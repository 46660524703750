import {ShowMandatoryTitleModel} from './ShowMandatoryTitle.model';
import {ValueEnabledModel} from './ValueEnabled.model';
import {ValueEnabledTitleModel} from './ValueEnabledTitle.model';
import {StoreCheckoutSettingsAndPayments} from '../../../utils/types.util';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {CountryCode} from '@wix/form-fields';
import {getEnabledDeliveryCountries} from '../../../utils/destinations.utils';

export class CheckoutSettingsModel {
  public storeName?: string;
  public logoUrl?: string;
  public taxName: string;
  public taxOnProduct: boolean;
  public customField: ShowMandatoryTitleModel;
  public termsAndConditions: ValueEnabledModel;
  public privacyPolicy: ValueEnabledModel;
  public returnPolicy: ValueEnabledModel;
  public digitalPolicy: ValueEnabledModel;
  public contactUs: ValueEnabledTitleModel;
  public customPolicy: ValueEnabledTitleModel;
  public arePoliciesCheckedByDefault: boolean;
  public areGeneralPoliciesEnabled: boolean;
  public isThereAnyPolicyEnabled: boolean;
  public isDigitalPolicyEnabled: boolean;
  public isCustomPolicyEnabled: boolean;
  public isSubscriptionCheckedByDefault: boolean;
  public isSubscriptionEnabled: boolean;
  public isGiftCardEnabled: boolean;
  public isGiftCardSupported: boolean;
  public paymentProviders?: string;
  public activePaymentMethods?: PaymentMethod[];
  public isLocalDeliveryByZipCodeEnabled: boolean;
  public mitEnabled: boolean;
  public delayCaptureEnabled?: boolean;
  public enabledDeliveryCountries: CountryCode[];

  constructor({
    checkoutSettingsAndPayments,
    taxName,
    shouldSupportDelayedCaptureOnCheckoutAndExpressButtons,
    shouldAddCustomPolicyToCheckboxesSection,
  }: {
    checkoutSettingsAndPayments?: StoreCheckoutSettingsAndPayments;
    taxName: string;
    shouldSupportDelayedCaptureOnCheckoutAndExpressButtons: boolean;
    shouldAddCustomPolicyToCheckboxesSection: boolean;
  }) {
    const checkoutSettings = checkoutSettingsAndPayments!.checkoutSettings;
    this.storeName = checkoutSettings.storeName ?? undefined;
    this.logoUrl = checkoutSettings.logo?.url;
    this.taxName = taxName;
    this.taxOnProduct = checkoutSettings.taxOnProduct ?? false;
    this.customField = new ShowMandatoryTitleModel(checkoutSettings.checkoutCustomField);
    this.termsAndConditions = new ValueEnabledModel(checkoutSettings.termsAndConditions);
    this.privacyPolicy = new ValueEnabledModel(checkoutSettings.privacyPolicy);
    this.returnPolicy = new ValueEnabledModel(checkoutSettings.refundCancellationPolicy);
    this.digitalPolicy = new ValueEnabledModel(checkoutSettings.digitalItemPolicy);
    this.contactUs = new ValueEnabledTitleModel(checkoutSettings.contactUs);
    this.customPolicy = new ValueEnabledTitleModel(checkoutSettings.customPolicy);
    this.arePoliciesCheckedByDefault = !!checkoutSettings.checkoutPolicyCheckbox?.checked;
    this.isThereAnyPolicyEnabled =
      this.termsAndConditions.enabled ||
      this.privacyPolicy.enabled ||
      this.returnPolicy.enabled ||
      (shouldAddCustomPolicyToCheckboxesSection && this.customPolicy.enabled);
    this.areGeneralPoliciesEnabled = !!checkoutSettings.checkoutPolicyCheckbox?.show && this.isThereAnyPolicyEnabled;
    this.isDigitalPolicyEnabled = !!checkoutSettings.checkoutPolicyCheckbox?.show && this.digitalPolicy.enabled;
    this.isCustomPolicyEnabled = !!checkoutSettings.checkoutPolicyCheckbox?.show && this.customPolicy.enabled;
    this.isSubscriptionCheckedByDefault = !!checkoutSettings.checkoutSubscriptionCheckbox?.checked;
    this.isSubscriptionEnabled = !!checkoutSettings.checkoutSubscriptionCheckbox?.show;
    this.isGiftCardEnabled = !!checkoutSettings.checkoutGiftCardCheckbox?.show;
    this.isGiftCardSupported = !!checkoutSettings.checkoutGiftCardCheckbox?.supported;
    this.activePaymentMethods = checkoutSettingsAndPayments!.payments.activePaymentMethods;
    this.paymentProviders = this.activePaymentMethods.map((paymentMethod) => paymentMethod.name).join(',');
    this.isLocalDeliveryByZipCodeEnabled =
      checkoutSettingsAndPayments?.localDeliveryByZipCodeFlag?.localDeliveryByZipCodeExists ?? false;
    this.mitEnabled = checkoutSettings.mitEnabled ?? false;
    this.delayCaptureEnabled = shouldSupportDelayedCaptureOnCheckoutAndExpressButtons
      ? checkoutSettings.delayCaptureEnabled ?? false
      : undefined;
    this.enabledDeliveryCountries = getEnabledDeliveryCountries(checkoutSettingsAndPayments?.destinations);
  }
}
